var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"data-app":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading_data_table,"headers":_vm.headers,"items":_vm.serviceGroups,"pageCount":_vm.numberOfPagesPaginate,"page":_vm.currentPagePaginate,"server-items-length":_vm.totalStagesPaginate,"options":_vm.options,"itemsPerPage":"15","footer-props":{
      itemsPerPageText: 'Itens por pagina',
      itemsPerPageOptions: [15,20,25],
      showFirstLastPage: true
    },"sort-by":"corporate_name"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Grupo de Serviços")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-8",attrs:{"rounded":"","outlined":"","dense":"","append-icon":"mdi-magnify","label":"Localizar","single-line":"","hide-details":""},on:{"click:append":function($event){return _vm.searchItems()},"change":function($event){return _vm.searchItems()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","rounded":"","dark":""},on:{"click":function($event){return _vm.newServiceGroup()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("Novo Grupo Serviço")],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.showServices(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-format-list-group ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"red","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}},{key:"no-data",fn:function(){return [_vm._v(" Nenhum registro encontrado ")]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('BarTitle',{attrs:{"title":_vm.formTitle},on:{"close":function($event){_vm.dialog = false}}}),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"label":"Código","outlined":""},model:{value:(_vm.editedItem.code),callback:function ($$v) {_vm.$set(_vm.editedItem, "code", $$v)},expression:"editedItem.code"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Descrição","outlined":""},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","x-large":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"primary",attrs:{"x-large":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Salvar")])],1)],1)],1),_c('MsgDelete',{attrs:{"loading":_vm.loadingDelete},on:{"close":function($event){_vm.dialogDelete = false},"confirm":_vm.deleteItemConfirm},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }